import React from "react"

import Header       from "../components/Header/upcc"
import WallPrograms from "../components/upcc/wall-programs"
import Programs     from "../components/upcc/programs"
import Footer       from "../components/share/footer"


export default () => {

  return (
    <>
      <Header/>

      <div class="upcc-container">

        <WallPrograms/>

        {/*<Programs/>*/}

      </div>

      <Footer/>
    </>
  )
}
